























































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import moment from "moment";

export default defineComponent({
  props: {
    value: {
      type: Object || null,
      required: false,
      default: () => ({}),
    },
    currency: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      hotels: [],
      isActive: false,
      error: false as boolean | number,
      accommodationDiscount: {},
      isRoommateCode: "without roommate",
      fromDate: "",
      toDate: "",
      minToDate: "",
    });

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`)
        .then(({ data: { hotels } }) => {
          state.hotels = hotels;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.hotels = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.fromDate = event.accommodationModule.from.split("T")[0];
          state.toDate = event.accommodationModule.to.split("T")[0];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchEvent);

    watch(
      () => state.isActive,
      () => {
        if (state.isActive === false) {
          props.value.roomId = "";
          props.value.from = "";
          props.value.to = "";
          props.value.roommate = null;
          props.value.roommateCode = null;
          props.value.reservationDate = "";
          props.value.discountCodeValue = null;
        }
      }
    );
    const getMinFromDate = () => {
      const date = new Date(props.value.from);
      const tempDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      state.minToDate = moment(tempDate).format("YYYY-MM-DD");
    };

    watch(() => props.value.from, getMinFromDate);

    const checkRoommateType = () => {
      const tempArray = state.hotels
        .map((el: any) => el.rooms)
        .flat()
        .map((item: any) => ({ id: item.id, roommate: item.roommate }));

      const element: any = tempArray.filter(
        (el: any) => el.id === props.value.roomId
      );
      state.isRoommateCode =
        element[0].roommate === "name and surname"
          ? "name and surname"
          : element[0].roommate === "participant code"
          ? "participant code"
          : "without roommate";
    };

    watch(() => props.value.roomId, checkRoommateType);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("panel.event.discount.discountCodeNotFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const checkDiscountCode = (code: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`discount-code/${code}/accommodation/${props.value.roomId}`)
        .then(({ data }) => {
          state.accommodationDiscount = data;
          props.value.discountCodeValue = code;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.discount.codeValid"),
          });
        })
        .catch((error) => {
          state.error = error.response?.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    return {
      state,
      checkDiscountCode,
    };
  },
});
